import React from "react"
import { Link } from "gatsby"
import { Grid, Box, Flex, Text, Button, Image } from "theme-ui"
import Time from "./adventures/Time"

export default function ShortStoryTile({ name, url, src, description, time }) {
  return (
    <Grid variant="base" columns={[1, "4fr 3fr"]} sx={{ alignItems: "center", bg: "grayXLight", gap: [4, 6, 8], py: 6, "&:not(:last-of-type)": { mb: 5 } }}>
      <Box>
        <Flex sx={{ alignItems: "baseline", flexWrap: "wrap", mb: 1 }}>
          <Text as="h2" variant="title01" sx={{ mb: 1, mr: 3 }}>{name}</Text>
          <Time time={time} />
        </Flex>
        <Text as="p" variant="body" sx={{ mb: 3 }}>{description}</Text>
        <Link to={url}>
          <Button variant="secondary">Explore</Button>
        </Link>
      </Box>
      
      <Image src={src} alt="Mischief Mountain" />
    </Grid>
  )
}
