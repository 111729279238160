import React from "react"
import { Grid, Text } from "theme-ui"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import ShortStoryTile from "../../components/ShortStoryTile"

import mischiefMountainImage from '../../images/spots/mischief-mountain.png'

const AdventuresPage = () => (
  <Layout>
    <SEO title="Starter adventure" />

    <Breadcrumbs title="Starter adventure" headline="Explore magical worlds" />

    {/* <Grid variant="textWrap" sx={{ mb: [7, 8] }}>
      <Text as="p" variant="bodyLarge">Our catalog of official adventures is growing. You can play Short Quests in a single game session. We'll add more official adventures to this page in 2021.</Text>
    </Grid> */}
      
    <ShortStoryTile
      name="Mischief Mountain"
      url="/adventures/mischief-mountain"
      src={mischiefMountainImage}
      description="The Trickster has lured the adventurers into their magic trap. Can they pass the test and win incredible treasures?"
      time="3-4 hours"
    />
  </Layout>
)

export default AdventuresPage